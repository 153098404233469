import { store } from "react-easy-state";
import app from "../stores/AppStore";
import error from "../stores/ErrorStore";
import axios from "axios";

const studentStore = store({
  datas: null,
  error: false,
  errorCode: null,

  async init() {
    // Avoid api flood call
    if (!studentStore.datas) {
      await axios
        .get(`${app.API_URL}students/${app.user_id}`)
        .then(response => {
          studentStore.datas = response.data;
        })
        .catch(e => {
          // status = HTTP status code
          studentStore.errorCode = e.response.status;
          studentStore.error = true;
          error.push(studentStore.errorCode);
        });
    }
    return true;
  }
});

export default studentStore;
