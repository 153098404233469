import React, { Component } from "react";
import AppBar from "../components/AppBar";
import { view } from 'react-easy-state';
import CitizenCard from "../components/CitizenCard";
import { withNamespaces } from "react-i18next";

class Me extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
  };

  render = () => {
    const { t } = this.props;
    return (
      <div>
        <AppBar title={t("ME.title")} />
        <main>
          <CitizenCard />
        </main>
      </div>
    );
  };
}

export default withNamespaces("translation")(view(Me));
