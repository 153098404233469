import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { view } from "react-easy-state";
import red from "@material-ui/core/colors/red";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

class CardError extends Component {
  render = () => {
    const { t } = this.props;
    return (
      <div className="grid simple" style={{ color: red[800], fontSize: 16 }}>
        <div className="item" style={{ padding: 0 }}>
          <ErrorOutline style={{ position: "relative", top: -3 }} />
        </div>
        <div className="item" style={{ paddingLeft: 5 }}>
          {t("PROBLEM_CARD")}
        </div>
      </div>
    );
  };
}

export default withNamespaces("translation")(view(CardError));
