import { store } from "react-easy-state";
import notificationStore from "../stores/NotificationStore";

const errorStore = store({
  push(code, param) {
    switch (code) {
      case 404:
        notificationStore.push("error", "API_ERROR");
        break;
      default:
        notificationStore.push("error", "CANCEL");
    }
  },
});

export default errorStore;