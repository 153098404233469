import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import app from "../stores/AppStore";
import bookmarkStore from "../stores/BookmarkStore";
import { view } from "react-easy-state";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import EditIcon from "@material-ui/icons/Edit";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Person from "@material-ui/icons/Person";
import Widgets from "@material-ui/icons/Widgets";
import School from "@material-ui/icons/School";
import Settings from "@material-ui/icons/Settings";
import BeachAccess from "@material-ui/icons/BeachAccess";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Notification from "./Notification";
import notificationStore from "../stores/NotificationStore";

class AppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false
    };
  }

  /*
   * String side : which side the drawer will open (*left*, bottom, right, top)
   * Bolean open : if opened
   */
  toggleDrawer = (side, open) => () => {
    if (open === "toggle") {
      this.setState({
        [side]: !this.state[side]
      });
    } else {
      this.setState({
        [side]: open
      });
    }
  };

  componentDidMount = () => {
    // Check if online
    window.addEventListener("load", () => {
      function handleNetworkChange(event) {
        if (!navigator.onLine) {
          notificationStore.push("default", "NO_CONNECTION");
        }
      }
      window.addEventListener("online", handleNetworkChange);
      window.addEventListener("offline", handleNetworkChange);
    });
  };

  render = () => {
    const { t } = this.props;
    return (
      <div>
        <header>
          <IconButton
            color="inherit"
            aria-label="Open menu"
            onClick={this.toggleDrawer("left", "toggle")}
          >
            <MenuIcon />
          </IconButton>
          <h1 className="f18">{t(this.props.title)}</h1>
          {this.props.edit && bookmarkStore.getCards.length > 1 && (
            <IconButton
              color="inherit"
              aria-label="Edit"
              component={Link}
              to="/reorder"
              style={{ float: "right" }}
            >
              <EditIcon />
            </IconButton>
          )}
        </header>

        <SwipeableDrawer
          open={this.state.left}
          onClose={this.toggleDrawer("left", false)}
          onOpen={this.toggleDrawer("left", true)}
        >
          <div style={{ height: "48px" }} />
          <List style={{ width: "240px" }}>
            <ListItem button component={Link} to="/dashboard">
              <ListItemIcon>
                <Widgets />
              </ListItemIcon>
              <ListItemText primary={t("DASHBOARD.title")} />
            </ListItem>
            <ListItem button component={Link} to="/citizen">
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary={t("ME.title")} />
            </ListItem>
            <ListItem button component={Link} to="/student">
              <ListItemIcon>
                <School />
              </ListItemIcon>
              <ListItemText primary={t("STUDENT.title")} />
            </ListItem>
            <ListItem button component={Link} to="/pension">
              <ListItemIcon>
                <BeachAccess />
              </ListItemIcon>
              <ListItemText primary={t("PENSION.title")} />
            </ListItem>
          </List>
          <Divider />
          <List style={{ width: "240px" }}>
            <ListItem button component={Link} to="/settings">
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary={t("SETTINGS.title")} />
            </ListItem>
          </List>
        </SwipeableDrawer>

        {app.loading && (
          <div className="content">
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          </div>
        )}

        <Notification />
      </div>
    );
  };
}

export default withNamespaces("translation")(view(AppBar));
