import { store } from "react-easy-state";

const bookmarkStore = store({

  get getCards() {
    if (!localStorage.getItem("storeCards")) {
      let cards = [];
      cards[0] = "citizen";
      localStorage["storeCards"] = JSON.stringify(cards);
    }
    return JSON.parse(localStorage.getItem("storeCards"));
  },

  add(name) {
    let cards = JSON.parse(localStorage.getItem("storeCards"));
    cards.push(name);
    localStorage["storeCards"] = JSON.stringify(cards);
  },

  remove(name) {
    let cards = JSON.parse(localStorage.getItem("storeCards"));
    let index = cards.indexOf(name);
    cards.splice(index, 1);
    localStorage["storeCards"] = JSON.stringify(cards);
  }
});

export default bookmarkStore;
