import { store } from "react-easy-state";
import app from "../stores/AppStore";
import error from "../stores/ErrorStore";
import axios from "axios";

const pensionStore = store({
  datas: null,
  error: false,
  errorCode: null,

  async init() {
    // Avoid api flood call
    if (!pensionStore.datas) {
      await axios
        .get(`${app.API_URL}pension/${app.user_id}`)
        .then(response => {
          pensionStore.datas = response.data;
        })
        .catch(e => {
          // status = HTTP status code
          pensionStore.errorCode = e.response.status;
          pensionStore.error = true;
          error.push(pensionStore.errorCode);
        });
    }
    return true;
  }
});

export default pensionStore;
