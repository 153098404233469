import React, { Component } from "react";
import AppBar from "../components/AppBar";
import { withNamespaces } from "react-i18next";
import { view } from 'react-easy-state';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Translate from "@material-ui/icons/Translate";
import Help from "@material-ui/icons/Help";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langDialog: false,
      aboutDialog: false
    };
  }

  componentDidMount = () => {
  };

  showLangDialog = () => {
    this.setState({
      langDialog: true
    });
  };

  showAboutDialog = () => {
    this.setState({
      aboutDialog: true
    });
  };

  handleCloseLangDialog = () => {
    this.setState({
      langDialog: false
    });
  };

  handleCloseAboutDialog = () => {
    this.setState({
      aboutDialog: false
    });
  };

  render = () => {
    const { t, i18n } = this.props;

    // List of languages
    const languages = [["fr", "Français"], ["nl", "Nederlands"]];

    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
      i18n.reloadResources();
      this.setState({
        langDialog: false
      });
    };

    return (
      <div>
        <AppBar title="SETTINGS.title" />
        <main>
          <List>
            <ListItem button onClick={this.showLangDialog}>
              <ListItemIcon>
                <Translate />
              </ListItemIcon>
              <ListItemText primary={t("SETTINGS.langApp")} />
            </ListItem>

            <Divider />
            <ListItem button onClick={this.showAboutDialog}>
              <ListItemIcon>
                <Help />
              </ListItemIcon>
              <ListItemText primary={t("SETTINGS.about")} />
            </ListItem>
          </List>

          {/* LANGUAGES */}
          <Dialog
            open={this.state.langDialog}
            onClose={this.handleCloseLangDialog}
            aria-labelledby={t("SETTINGS.langApp")}
          >
            <DialogTitle>{t("SETTINGS.langApp")}</DialogTitle>
            <div>
              <List>
                {languages.map(lang => (
                  <ListItem
                    button
                    onClick={() => changeLanguage(lang[0])}
                    key={lang[0]}
                  >
                    <ListItemText primary={lang[1]} />
                  </ListItem>
                ))}
              </List>
            </div>
            <DialogActions>
              <Button onClick={this.handleCloseLangDialog} color="primary">
                {t("CANCEL")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* ABOUT */}
          <Dialog
            open={this.state.aboutDialog}
            onClose={this.handleCloseAboutDialog}
            scroll={this.state.scroll}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              {t("SETTINGS.about")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className="f14">
                Aliquam venenatis quis turpis at consectetur. Sed dapibus ornare
                odio, eget vehicula arcu lobortis quis. Fusce et dolor risus.
                Donec eu ante vestibulum, congue risus eget, accumsan leo.
                Aliquam dignissim in lacus sit amet porttitor. Ut pretium nec
                leo id bibendum. Nullam ac molestie enim. Sed mattis pretium
                est, sodales dapibus est fringilla at. Sed ut lacinia enim.
                Morbi vitae vestibulum sapien. Nam nec tincidunt justo, aliquet
                cras amet.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseAboutDialog} color="primary">
                {t("CLOSE")}
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </div>
    );
  };
}

export default withNamespaces("translation")(view(Settings));
