import React, { Component } from "react";
import AppBar from "../components/AppBar";
import { withNamespaces } from "react-i18next";

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
  };

  render = () => {
    const { t } = this.props;
    return (
      <div>
        <AppBar title={t("ERROR.title")} />
        <main>
          <h2>{t("ERROR.text")}</h2>
        </main>
      </div>
    );
  };
}

export default withNamespaces("translation")(Error);
