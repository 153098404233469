import React, { Component } from "react";
import AppBar from "../components/AppBar";
import { withNamespaces } from "react-i18next";
import { view } from 'react-easy-state';
import CitizenCard from "../components/CitizenCard";
import StudentCard from "../components/StudentCard";
import PensionCard from "../components/PensionCard";
import bookmarkStore from "../stores/BookmarkStore";

class Dashboard extends Component {
  intervalID = 0;

  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      isEmpty: false
    };
  }

  componentDidMount = () => {
    this.getCards();
  };

  getCards = () => {
    let myCards = bookmarkStore.getCards;
    if (myCards.length === 0) {
      this.setState({ isEmpty: true });
    } else {
      let tmpArray = [];
      myCards.forEach(function (el) {
        switch (el) {
          case "citizen":
            tmpArray.push(<CitizenCard key={el} />);
            break;
          case "student":
            tmpArray.push(<StudentCard key={el} />);
            break;
          case "pension":
            tmpArray.push(<PensionCard key={el} />);
            break;
          default:
        }
      });
      this.setState(prevState => ({ cards: [...prevState.cards, tmpArray] }));
    }
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className="dashboard">
        <AppBar title={t("DASHBOARD.title")} edit={true} />
        <main>
          {this.state.isEmpty && (
            <div className="empty">{t("DASHBOARD.isEmpty")}</div>
          )}
          {this.state.cards}
        </main>
      </div>
    );
  };
}

export default withNamespaces("translation")(view(Dashboard));
