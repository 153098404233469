import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import red from "@material-ui/core/colors/red";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class Login extends Component {
  state = {
    open: false,
    scroll: "paper",
    offline: false
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ offline: false });
  };

  componentDidMount = () => {
    let that = this;
    // Offline watcher o^o
    window.addEventListener("load", () => {
      function handleNetworkChange(event) {
        if (navigator.onLine) {
          console.log("ONLINE");
        } else {
          that.setState({ offline: true });
          console.log("OFFLINE");
        }
      }
      window.addEventListener("online", handleNetworkChange);
      window.addEventListener("offline", handleNetworkChange);
    });
  };

  render = () => {
    const { t, i18n } = this.props;
    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };

    return (
      <div className="login">
        <main>
          <Grid container spacing={24}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => changeLanguage("fr")}
                style={{ color: "white" }}
              >
                fr
              </Button>
              <Button
                onClick={() => changeLanguage("nl")}
                style={{ color: "white" }}
              >
                nl
              </Button>
            </Grid>
            <Grid item xs={12}>
              <h1>beApp</h1>
            </Grid>
            <Grid container justify="center">
              <Button
                variant="extendedFab"
                aria-label="Login"
                onClick={this.handleClickOpen("paper")}
              >
                {t("LOGIN.login")}
              </Button>
            </Grid>
          </Grid>
        </main>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            {t("LOGIN.cgu")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="f14">
              Cras mattis consectetur purus sit amet fermentum. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Nunc semper posuere
              felis, non placerat odio porttitor eleifend. Donec eu porttitor
              nibh, accumsan hendrerit lacus. Donec eget felis magna. Cras
              cursus mauris et ligula bibendum, ut lacinia dolor rhoncus. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos. Proin quis tellus gravida, tempus justo eget,
              viverra metus. Proin semper mi elit, quis placerat enim
              sollicitudin eu. Maecenas eu sodales dui. Nulla malesuada est in
              ipsum pharetra laoreet. Integer eget lectus sed augue placerat
              pellentesque a quis nibh. Maecenas aliquam mauris quis lorem
              pellentesque, nec vulputate ante dignissim. Integer convallis
              dolor sed nulla facilisis laoreet. Pellentesque ultrices interdum
              magna, eget tristique ipsum venenatis quis. In fermentum pulvinar
              elit. In imperdiet rutrum elementum. Maecenas urna dolor,
              sollicitudin vel dui sed, rhoncus vehicula quam. Nam hendrerit
              aliquam auctor. Nulla ut congue dolor. Nulla lobortis rhoncus
              tellus at tristique. Mauris imperdiet est ante, placerat
              ullamcorper nunc commodo ut. Proin suscipit mollis eros sit amet
              rhoncus. Sed tincidunt laoreet scelerisque. Morbi nibh elit,
              varius vitae nibh a, luctus blandit nunc. Phasellus placerat
              lectus sed sem fringilla, ut dignissim urna condimentum. Nulla at
              hendrerit elit, vitae gravida augue. Nullam purus erat, faucibus
              non magna sed, tempus rhoncus velit. Praesent nec rhoncus nisl.
              Nulla vulputate lectus lacus, vitae ultrices erat sodales eget.
              Vivamus et erat imperdiet, sollicitudin tellus dapibus, bibendum
              sapien. Nulla congue feugiat gravida. Nam accumsan pellentesque
              eros vitae vehicula. Aliquam vestibulum odio sit amet feugiat
              mattis. In velit nibh, elementum eu tellus ut, cursus maximus
              neque. Donec ultricies mi eget dui vestibulum aliquet. Quisque
              ultricies elit sapien, ac consequat magna iaculis ut. Pellentesque
              et dolor turpis. Nunc egestas ullamcorper nisi in posuere.
              Suspendisse maximus neque metus, luctus placerat massa
              pellentesque nec. Sed eu lacus risus. Ut aliquet dictum maximus.
              Donec nec justo sed enim scelerisque sagittis ut quis sem. Ut
              maximus pharetra libero eu interdum. Fusce sollicitudin accumsan
              turpis eget faucibus. Vestibulum pretium urna ut massa iaculis
              imperdiet. Integer efficitur lorem at accumsan iaculis. Aenean
              rhoncus semper vehicula. Aliquam et imperdiet metus, vitae
              volutpat urna. Curabitur ac mattis nisi. Cras quis dui ex. Nam id
              ipsum eget dui iaculis vulputate eu et sapien. Quisque ut
              vestibulum est, ut convallis urna. Cras mauris felis, iaculis at
              lacus vel, ornare fringilla justo. Duis sed ultricies leo,
              fringilla tempus odio. Sed enim libero, sollicitudin eget
              pellentesque a, laoreet id nulla. Curabitur non nisl dolor. Nulla
              felis massa, hendrerit et erat ac, fringilla elementum ante.
              Suspendisse a nisl nunc. Phasellus consequat sit amet massa in
              porttitor. Mauris lobortis vestibulum elit, eu consequat turpis
              facilisis id. Mauris nec arcu cursus, euismod erat non, luctus
              ante. Aliquam et magna tincidunt, rhoncus tortor sit amet,
              ultrices mauris. Quisque venenatis auctor imperdiet. Donec velit
              felis, sodales a auctor ac, laoreet id lacus. Nam eleifend lacus
              non nulla consectetur pharetra. Nunc rhoncus egestas odio at
              aliquet. Donec feugiat nisi ullamcorper, tempus arcu at, vulputate
              justo. Aliquam erat volutpat. Mauris velit nisl, auctor eget
              egestas vestibulum, cursus vel nunc. Sed malesuada, magna.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              component={Link}
              to="/dashboard"
              onClick={this.handleClose}
              color="primary"
            >
              {t("LOGIN.accept")}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.offline}
          autoHideDuration={60000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
            style: {
              backgroundColor: red[800]
            }
          }}
          message={<span id="message-id">{t("NO_CONNECTION")}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  };
}

export default withNamespaces("translation")(Login);
