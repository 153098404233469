import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Route from "./routes";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

ReactDOM.render(<Route />, document.getElementById("root")
);

// If you want beApp to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
