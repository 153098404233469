import React, { Component } from "react";
import AppBar from "../components/AppBar";
import { withNamespaces } from "react-i18next";
import bookmarkStore from "../stores/BookmarkStore";
import Reorder from "@material-ui/icons/Reorder";
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from "react-sortable-hoc";

const SortableItem = SortableElement(({ value }) => (
  <div className="cards">
    <Reorder style={{ color: "#848484" }} />
    <h2
      style={{
        display: "inline-block",
        position: "relative",
        top: "-.35rem",
        margin: "0 0 0 1rem"
      }}
    >
      {value}
    </h2>
  </div>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <div className="reorder">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
});

class EditDashboard extends Component {
  intervalID = 0;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cards: []
    };
  }

  componentDidMount = () => {
    this.setState({ cards: bookmarkStore.getCards });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      cards: arrayMove(this.state.cards, oldIndex, newIndex)
    });
    localStorage["storeCards"] = JSON.stringify(this.state.cards);
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className="dashboard">
        <AppBar
          title={t("REORDER.title")}
          loading={this.state.loading}
          error={this.state.error}
        />
        <main>
          <SortableList helperClass="active-sort" items={this.state.cards} onSortEnd={this.onSortEnd} />
        </main>
      </div>
    );
  };
}

export default withNamespaces("translation")(EditDashboard);
