import { store } from "react-easy-state";

const notificationStore = store({
  display: false,
  action: null,
  message: null,

  push(action, message) {
    notificationStore.display = true;
    notificationStore.action = action;
    notificationStore.message = message;
  },
});

export default notificationStore;