import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { view } from "react-easy-state";

import pensionStore from "../stores/PensionStore";
import bookmarkStore from "../stores/BookmarkStore";
import notificationStore from "../stores/NotificationStore";
import BeachAccess from "@material-ui/icons/BeachAccess";
import IconButton from "@material-ui/core/IconButton";
import StarBorder from "@material-ui/icons/StarBorder";
import Star from "@material-ui/icons/Star";
import CardError from "./CardError";

class PensionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "pension", // name of the card
      pension: null,
      error: false,
      bookmarked: false,
      bookmarkedSnackbar: false,
      bookmarkedText: null
    };
  }

  async componentDidMount() {
    await pensionStore.init();
    this.setState({ citizen: pensionStore.datas });
    if (localStorage.getItem("storeCards").indexOf(this.state.name) > -1) {
      this.setState({ bookmarked: true });
    }
  };

  removeBookmark = () => {
    bookmarkStore.remove(this.state.name);
    notificationStore.push("success", "BOOKMARK_REMOVE");
    this.setState({ bookmarked: false });
  };

  addBookmark = () => {
    bookmarkStore.add(this.state.name);
    notificationStore.push("success", "BOOKMARK_ADD");
    this.setState({ bookmarked: true });
  };

  renderPension = () => {
    const { t } = this.props;
    const pension = this.state.pension;
    return (
      <div className={this.state.bookmarked ? "null" : "fadeOut"}>
        <div className="cards">
          <div className="grid">
            <div className="item">
              <h2>
                <BeachAccess
                  color="primary"
                  className="icon"
                  style={{ top: "3px", fontSize: "20px" }}
                />
                {t("PENSION.title")}
              </h2>
            </div>
            <div className="item">
              {this.state.bookmarked ? ( // if bookmarked
                <IconButton
                  style={{
                    color: "#FFA000",
                    position: "absolute",
                    top: "5px",
                    right: "0"
                  }}
                  aria-label="Favoris"
                  onClick={this.removeBookmark}
                >
                  <Star />
                </IconButton>
              ) : (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "0"
                    }}
                    color="default"
                    aria-label="Favoris"
                    onClick={this.addBookmark}
                  >
                    <StarBorder />
                  </IconButton>
                )}
            </div>
          </div>
          {pension ? ( // if we've datas
            <div>
               {/* We don't have datas */}
            </div>
          ) : (
              <div>
                {/* If we have an error it means that the data (citizen) is empty and the loading must stop */}
                {pensionStore.error ? (
                  <div><CardError /></div>
                ) : (
                    <div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 50 + 1) + 50) + "%" }}></div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%" }}></div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%" }}></div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%" }}></div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%", margin: 0 }}></div>
                    </div>
                  )}
              </div>
            )}
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        {this.renderPension()}
      </div>
    );
  };
}

export default withNamespaces("translation")(view(PensionCard));
