import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { view } from "react-easy-state";
import notificationStore from "../stores/NotificationStore";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

class Notification extends Component {

  handleClose = () => {
    notificationStore.display = false;
  };

  colorPicker = () => {
    let color = notificationStore.action;
    if (color === "success") {
      return green[600];
    } else if (color === "error") {
      return red[800];
    } else {
      return grey[900];
    }
  };

  render = () => {
    const { t } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={notificationStore.display}
          onClose={this.handleClose}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id",
            style: {
              backgroundColor: this.colorPicker()
            }
          }}
          message={<span id="message-id">{t(notificationStore.message)}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  };
}

export default withNamespaces("translation")(view(Notification));
