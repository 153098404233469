import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { view } from 'react-easy-state';
import Error from "./views/Error";
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import EditDashboard from "./views/EditDashboard";
import Settings from "./views/Settings";
import Student from "./views/Student";
import Pension from "./views/Pension";
import Citizen from "./views/Citizen";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#4298aa",
      main: "#137f95",
      dark: "#0d5868",
      contrastText: "#fff"
    },
    secondary: {
      light: "#5393ff",
      main: "#2979ff",
      dark: "#1c54b2",
      contrastText: "#000"
    }
  }
});

class Routes extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline />
          <BrowserRouter>
            <Switch>
              <Route path="/" component={Login} exact />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/reorder" component={EditDashboard} />
              <Route path="/citizen" component={Citizen} />
              <Route path="/settings" component={Settings} />
              <Route path="/student" component={Student} />
              <Route path="/pension" component={Pension} />
              <Route path="*" component={Error} />
            </Switch>
          </BrowserRouter>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

export default view(Routes);
