import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { view } from "react-easy-state";
import citizenStore from "../stores/CitizenStore";
import bookmarkStore from "../stores/BookmarkStore";
import notificationStore from "../stores/NotificationStore";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import StarBorder from "@material-ui/icons/StarBorder";
import Star from "@material-ui/icons/Star";

class CitizenCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "citizen", // name of the card
      citizen: null,
      error: false,
      bookmarked: false,
      bookmarkedSnackbar: false,
      bookmarkedText: null
    };
  }

  async componentDidMount() {
    await citizenStore.init();
    this.setState({ citizen: citizenStore.datas });
    if (localStorage.getItem("storeCards").indexOf(this.state.name) > -1) {
      this.setState({ bookmarked: true });
    }
  };

  removeBookmark = () => {
    bookmarkStore.remove(this.state.name);
    notificationStore.push("success", "BOOKMARK_REMOVE");
    this.setState({ bookmarked: false });
  };

  addBookmark = () => {
    bookmarkStore.add(this.state.name);
    notificationStore.push("success", "BOOKMARK_ADD");
    this.setState({ bookmarked: true });
  };

  renderCitizen = () => {
    const { t } = this.props;
    const citizen = this.state.citizen;
    return (
      <div className={this.state.bookmarked ? "null" : "fadeOut"}>
        <div className="cards">
          <div className="grid">
            <div className="item">
              <h2>
                <AccountCircle
                  color="primary"
                  className="icon"
                  style={{ top: "3px", fontSize: "20px" }}
                />
                {t("ME.contactInfos")}
              </h2>
            </div>
            <div className="item">
              {this.state.bookmarked ? ( // if bookmarked
                <IconButton
                  style={{
                    color: "#FFA000",
                    position: "absolute",
                    top: "5px",
                    right: "0"
                  }}
                  aria-label="Favoris"
                  onClick={this.removeBookmark}
                >
                  <Star />
                </IconButton>
              ) : (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "0"
                    }}
                    color="default"
                    aria-label="Favoris"
                    onClick={this.addBookmark}
                  >
                    <StarBorder />
                  </IconButton>
                )}
            </div>
          </div>
          {citizen ? ( // if we've datas
            <div>
              <div className="item">
                <span className="f18 db">
                  {citizen.firstName} {citizen.lastName}
                </span>
              </div>
              <div className="item">
                <span className="fw600">Date de naissance : </span>
                <span>{citizen.dateOfBirth}</span>
              </div>
              <div className="item">
                <span className="fw600">Addresses :</span>
                {citizen.addresses.map(address => {
                  return (
                    <div className="item" key={address.addressLine}>
                      {address.addressLine}
                      <br />
                      {address.postalCode} {address.city}
                      <br />
                      {address.country}
                    </div>
                  );
                })}
              </div>
              <div className="item">
                <Divider />
              </div>
              <div className="item">
                <span className="fw600">Téléphone :</span>
                <span>{citizen.phone}</span>
              </div>
              <div className="item">
                <span className="fw600">E-mail : </span>
                <span>{citizen.email}</span>
              </div>
            </div>
          ) : (
              <div>
                {/* If we have an error it means that the data (citizen) is empty and the loading must stop */}
                {citizenStore.error ? (
                  <div>{t("PROBLEM_CARD")}</div>
                ) : (
                    <div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 50 + 1) + 50) + "%" }}></div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%" }}></div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%" }}></div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%" }}></div>
                      <div className="content-placeholder" style={{ width: Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%", margin: 0 }}></div>
                    </div>
                  )}
              </div>
            )}
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        {this.renderCitizen()}
      </div>
    );
  };
}

export default withNamespaces("translation")(view(CitizenCard));
