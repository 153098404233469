import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { view } from "react-easy-state";
import studentStore from "../stores/StudentStore";
import bookmarkStore from "../stores/BookmarkStore";
import notificationStore from "../stores/NotificationStore";
import School from "@material-ui/icons/School";
import IconButton from "@material-ui/core/IconButton";
import StarBorder from "@material-ui/icons/StarBorder";
import Star from "@material-ui/icons/Star";

class StudentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "student", // name of the card
      student: null,
      error: false,
      bookmarked: false,
      bookmarkedSnackbar: false,
      bookmarkedText: null
    };
  }

  async componentDidMount() {
    await studentStore.init();
    this.setState({ student: studentStore.datas });
    if (localStorage.getItem("storeCards").indexOf(this.state.name) > -1) {
      this.setState({ bookmarked: true });
    }
  }

  removeBookmark = () => {
    bookmarkStore.remove(this.state.name);
    notificationStore.push("success", "BOOKMARK_REMOVE");
    this.setState({ bookmarked: false });
  };

  addBookmark = () => {
    bookmarkStore.add(this.state.name);
    notificationStore.push("success", "BOOKMARK_ADD");
    this.setState({ bookmarked: true });
  };

  renderStudent = () => {
    const { t } = this.props;
    const student = this.state.student;
    return (
      <div className={this.state.bookmarked ? "null" : "fadeOut"}>
        <div className="cards">
          <div className="grid">
            <div className="item">
              <h2>
                <School
                  color="primary"
                  className="icon"
                  style={{ top: "3px", fontSize: "20px" }}
                />
                {t("STUDENT.cardTitle")}
              </h2>
            </div>
            <div className="item">
              {this.state.bookmarked ? (
                <IconButton
                  style={{
                    color: "#FFA000",
                    position: "absolute",
                    top: "5px",
                    right: "0"
                  }}
                  aria-label="Favoris"
                  onClick={this.removeBookmark}
                >
                  <Star />
                </IconButton>
              ) : (
                <IconButton
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "0"
                  }}
                  color="default"
                  aria-label="Favoris"
                  onClick={this.addBookmark}
                >
                  <StarBorder />
                </IconButton>
              )}
            </div>
          </div>
          {student ? ( // if we've datas
            <div className="item">
              <h3>{t("STUDENT.remainingHours")}</h3>
              <span className="green f24 fw600">
                {student.remainingHours} {t("STUDENT.remainingHoursUnit")}
              </span>
            </div>
          ) : (
            <div>
              {/* If we have an error it means that the data (student) is empty and the loading must stop */}
              {studentStore.error ? (
                <div>{t("PROBLEM_CARD")}</div>
              ) : (
                <div>
                  <div
                    className="content-placeholder"
                    style={{
                      width:
                        Math.floor(Math.random() * (100 - 50 + 1) + 50) + "%"
                    }}
                  />
                  <div
                    className="content-placeholder"
                    style={{
                      width:
                        Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%"
                    }}
                  />
                  <div
                    className="content-placeholder"
                    style={{
                      width:
                        Math.floor(Math.random() * (100 - 20 + 1) + 20) + "%",
                      margin: 0
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div>
        {this.renderStudent()}
      </div>
    );
  };
}

export default withNamespaces("translation")(view(StudentCard));
