import { store } from "react-easy-state";
import app from "../stores/AppStore";
import error from "../stores/ErrorStore";
import axios from "axios";

const citizenStore = store({
  datas: null,
  error: false,
  errorCode: null,

  async init() {
    // Avoid api flood call
    if (!citizenStore.datas) {
      await axios
        .get(`${app.API_URL}citizens/${app.user_id}`)
        .then(response => {
          citizenStore.datas = response.data;
        })
        .catch(e => {
          // status = HTTP status code
          citizenStore.errorCode = e.response.status;
          citizenStore.error = true;
          error.push(citizenStore.errorCode);
        });
    }
    return true;
  }
});

export default citizenStore;
